import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import iPad01 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(1).webp'
import iPad02 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(2).webp'
import iPad03 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(14).webp'
import iPad04 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(13).webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Open the Multiview menu and click on the + button at the top right to add your new camera"
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Select Add New P2P Camera to add your camera via its UID."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="It is recommended to use the QRCode Reader here to scan the code in the web interface of your camera. Alternatively, you can also search for your camera in the local network or enter the UID manually."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="If you have used the search function, select your camera from the list of P2P devices found on your local network."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function P2PGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default P2PGallery